<template>
	<div>
		<div class="row mb-3">
			<div class="col-12">
				<h1><em class="fas fa-info-circle"></em> Company Numbers</h1>
			</div>
		</div>

		<div class="text-center">Coming Soon!</div>
	</div>
</template>

<script>
export default
{
	name: 'Numbers',
	props: ["session_id", "logged_in"],
	mounted: function()
	{
		if(!this.logged_in)
		{
			return;
		}
	}
}
</script>
